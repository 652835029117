<template>
  <div class="form">
    <div class="background" v-if="cityImage">
      <div class="background__image" :style="'background-image: url(' + getCityImageUrl(cityImage) + ');'"></div>
    </div>

    <div class="wrapper" v-if="isContent">
      <Backlink title="set_meeting_title" :backlink="{ name: 'Bank offers', params: { service_id: service_id} }" />
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "set_meeting_title" | localize }}</p>
          <form @submit.prevent="submitHandler">
            <div class="form__group scroll_point">
              <v-autocomplete
                v-model="city"
                :items="cities"
                @blur="$v.city.$touch()"
                @change="checkForm($v.city); loadBranches()"
                :class="{ invalid: $v.city.$dirty && !$v.city.required }"
                item-text="name"
                item-value="value"
                cache-items
                :label="'city' | localize"
                :placeholder="'s2p4_placeholder_3' | localize"
                append-icon="icon-arrow-down"
                ref="city"
                outlined
              ></v-autocomplete>

              <v-select
                  v-model="branch"
                  :items="branches"
                  item-text="name"
                  item-value="value"
                  :class="{ invalid: $v.branch.$dirty && !$v.branch.required }"
                  @change="setBranch(); setTimes(); checkForm($v.branch)"
                  :label="'branch' | localize"
                  :placeholder="'select' | localize"
                  :menu-props="{ offsetY: true }"
                  append-icon="icon-arrow-down"
                  ref="branch"
                  outlined
              ></v-select>
            </div>

            <div class="form__group">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date_formatted"
                      class="v-input-calendar input_number"
                      v-mask.hide-on-empty="dateMask"
                      @focus="date = parseDate(date_formatted)"
                      @blur="date = parseDate(date_formatted); $v.date.$touch()"
                      @change="setTimes"
                      :class="{ invalid: ($v.date.$dirty && !$v.date.required) || ($v.date.$dirty && !$v.date.validDate) }"
                      :hint="hintArray($v.date, [{rule: $v.date.required, message: 'form_reqiered'}, {rule: $v.date.validDate, message: 'form_wrong_date'}]) | localize"
                      :placeholder="'s1p2_date' | localize"
                      outlined
                      persistent-hint
                      append-icon="icon-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @change="checkForm($v.date); setTimes()"
                  no-title
                  full-width
                  :min="dateFrom"
                  @input="menu = false; date_formatted = formatDate(date)"
                ></v-date-picker>
              </v-menu>

              <v-select
                  v-model="time"
                  :items="times"
                  item-text="name"
                  item-value="value"
                  @change="checkForm($v.time)"
                  :label="'time' | localize"
                  :class="{ invalid: $v.time.$dirty && !$v.time.required }"
                  :placeholder="'select' | localize"
                  :menu-props="{ offsetY: true }"
                  append-icon="icon-arrow-down"
                  ref="time"
                  outlined
              ></v-select>
            </div>

            <div class="form__group">
                <v-textarea
                  auto-grow
                  outlined
                  ref="comment"
                  class="meet_comment"
                  v-model="comment"
                  :label="'comment' | localize"
                  :placeholder="'comment' | localize"
                ></v-textarea>
            </div>

            <div class="info info_map" v-if="nearestBranch">
              {{ 'nearest_branch' | localize }} ({{ nearestBranchAddress }}): {{ nearestBranch }}
            </div>
            <div class="info info_map" v-if="branchInfo">
                {{ 'form_phone_number' | localize }}: {{ branchInfo.phone }} <br>
                {{ branchInfo.address }} <br>
                {{ branchInfo.description }}
            </div>

            <gmap-map
                class="meetmap"
                :center="center"
                :zoom="zoom"
                :options="options"
                ref="map"
            >
                <gmap-marker
                    v-if="marker"
                    :clickable="true"
                    @click="infoWinOpen = !infoWinOpen"
                    :position="center"
                ></gmap-marker>
                <gmap-info-window
                    v-if="marker"
                    @closeclick="infoWinOpen=false"
                    :opened="infoWinOpen"
                    :options="infoOptions"
                    :position="center"
                ></gmap-info-window>
            </gmap-map>

            <div class="error__container error__container_button">
              <p class="error" v-if="error">{{ error | localize }}</p>
              <p class="error" v-if="!success && check_form">{{ 'field_required' | localize }}</p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "meet_button" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="popup popup_active" v-if="$store.state.popup.id" :class="$store.state.popup.type">
        <div class="popup__overlay popup__overlay_active">
            <div class="popup__center" @click.self="$store.commit('togglePopupText')">
                <div class="popup__window popup__window_blue">
                    <button class="close popup__close" @click.self="$store.commit('togglePopupText')"></button>
                    <div class="popup__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
                    <div class="popup__scrollcontainer ">
                        <div class="popup__scrollcontainer_inner">
                          <p class="meet_center meet_center_position">{{ "set_meeting" | localize }}</p>
                          <div class="meet_block">
                            <div class="meet_blockItem meet_green">{{ "meet_complete" | localize }}</div>
                            <div class="meet_blockItem">
                              <div><span class="meet_text">{{ "date" | localize }}:</span><span class="meet_green">{{ formatDate(date) }}</span></div>
                              <div><span class="meet_text">{{ "time" | localize }}:</span><span class="meet_green">{{ time }}</span></div>
                              <div><span class="meet_text">{{ "place" | localize }}:</span><span class="meet_green">{{ branches.length > 0 ? branches.find(b => (branch === b.value)).name : '' }}</span></div>
                            </div>
                          </div>
                          <p class="meet_center" v-if="approved">{{ "meet_approved" | localize }}</p>
                          <p class="meet_center" v-else>{{ "meet_info" | localize }}</p>
                        </div>
                    </div>
                    <div class="button__container">
                        <button type="button" class="button button__form" @click="finish">{{ "continue" | localize }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Backlink from '@/components/base/Backlink.vue'
import { commonMixin } from '@/mixins/commonMixin.js'
import { formMixin } from '@/mixins/formMixin.js'

export default {
  name: 'Bank_meeting',
  components: { Backlink },
  props: {
    bank_id: { type: Number, required: true },
    service_id: { type: Number, required: true }
  },
  mixins: [commonMixin, formMixin],
  data: () => ({
    cities: [],
    branches: [],
    times: [],

    city: null,
    branch: null,
    time: null,
    comment: null,

    date: null,
    dateFrom: new Date().toISOString().substr(0, 10),
    date_formatted: null,
    dateMask: [/\d/, /\d/, ' / ', /\d/, /\d/, ' / ', /\d/, /\d/, /\d/, /\d/],
    menu: false,

    branchInfo: null,
    nearestBranch: null,
    nearestBranchAddress: null,
    cityImage: null,
    marker: false,
    zoom: 8,
    center: {
      lat: 31.5313113,
      lng: 34.8667654
    },
    options: {
      mapTypeControl: false,
      streetViewControl: false
    },
    infoWinOpen: true,
    infoOptions: {
      content: '',
      pixelOffset: { width: 0, height: -35 }
    },

    approved: false,
    check_form: false,
    success: false,
    error: null
  }),
  validations: {
    date: { required, validDate: v => formMixin.methods.isValidDate(v) },
    branch: { required },
    time: { required },
    city: { required }
  },
  computed: {
    popup_trigger () { 
      return this.$store.state.popup
    }
  },
  watch: { 
    popup_trigger () { 
      this.showContent()
    }
  },
  created () {
    // Переход к выбору банка
    if (!this.bank_id) {
      this.$router.push({ name: 'Bank offers' })
    }
    this.showContent()
  },
  mounted () {
    // Загрузка встреч
    this.$store.commit('loading', true)
    this.axios.get('meet', {
      params: {
        user_id: this.$store.state.user.id || null,
        service_id: this.service_id,
        bank_id: this.bank_id
      }
    }).then((response) => {
      if (response.data && response.data.bank_id) {
        this.loadBranches()
        this.$store.commit('togglePopupText', { id: 1, type: 'popup_info' })
        this.approved = response.data.status === 'accepted'
        this.date = response.data.dt
        this.time = response.data.time
        this.branch = response.data.branch_id
        this.comment = response.data.comment
      }
      this.$store.commit('loading', false)
    }).catch((error) => {
      this.$store.commit('loading', false)
      this.error = error.response.data.error ?? 'server_error'
      this.$scrollTo('.error__container', 300, { offset: -400 })
    })

    const localeName = 'name_' + this.$store.state.lang
    this.axios
      .get('cities?sort=population&projection=id,' + localeName)
      .then((response) => {
        this.cities = response.data.map((e) => ({
          name: e[localeName],
          value: e.id
        }))
        // Загрузка города пользователя
        this.axios.get('clients/city', {
          params: {
            user_id: this.$store.state.user.id,
            service_id: this.service_id
          }
        }).then((response) => {
          this.city = response.data.city
          this.cityImage = response.data.city_image
          this.loadBranches()
        }).catch((error) => {
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
      })
      .catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  },
  methods: {
    getCityImageUrl (path) {
      return process.env.VUE_APP_ADMIN_URL + '/storage/' + path
    },
    showContent () {
      this.isContent = !this.isMobile || !this.$store.state.popup.id && (!this.isMobile || !this.$store.state.is_popup_shown)
    },
    finish () {
      // Закрыть окно
      this.$store.commit('togglePopupText')

      // Переход на страницу услуги ЛК
      this.$router.push({ name: 'services' })
    },
    submitHandler () {
      this.check_form = true
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      this.success = true
      this.$store.commit('loading', true)
      this.axios.post('meet', {
        user_id: this.$store.state.user.id || null,
        service_id: this.service_id,
        bank_id: this.bank_id,
        branch_id: this.branch,
        date: this.date,
        time: this.time,
        comment: this.comment
      }).then(() => {
        this.$store.commit('loading', false)
        this.$store.commit('togglePopupText', { id: 1, type: 'popup_info' })
      }).catch((error) => {
        this.$store.commit('loading', false)
        this.error = error.response.data.error ?? 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    },
    loadBranches () {
      // Загрузка филиалов, дней приема и времени приема
      this.axios.get('bank_branches', {
        params: { bank_id: this.bank_id, city_id: this.city }
      }).then((response) => {
        this.branches = response.data.map(e => ({ name: e.name, value: e.id, data: e }))
        this.getNearestBranch()
      }).catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    },
    getNearestBranch() {
      this.nearestBranch = null

      // Загрузка данных пользователя
      this.$store.commit('loading', true)
      this.axios.get('users/' + this.$store.state.user.id || null).then((response) => {

        // Определение ближайшего филиала по адресу пользователя
        const API_KEY = process.env.VUE_APP_MAPQUEST_API_KEY
        const user_latlon = response.data.latlon
        const user_city = this.cities.find(c => c.value == response.data.city_id).name
        const user_street = response.data.street
        const user_house = response.data.house
        const branch_latlons = this.branches.map(e => e.data.latlon || e.data.address )

        const axios = this.axios.create({
          transformRequest: [(data, headers) => {
            delete headers.common['Locale']
            delete headers.common['X-Authorization']
            return data
          }, ...this.axios.defaults.transformRequest]
        })

        axios.post('https://open.mapquestapi.com/directions/v2/routematrix?key='+ API_KEY, {
          locations: [ user_latlon, ...branch_latlons ],
          options: { manyToOne: true }
        }).then((response) => {   
          const distances = response.data.distance

          if (Array.isArray(distances)) {
            distances.shift()
            const branchIdx = distances.reduce((r, v, i, a) => v >= a[r] ? r : i, -1)
            this.branch = this.branches[branchIdx].value
            this.setBranch()
            this.nearestBranch = this.branches[branchIdx].name
            this.nearestBranchAddress = user_city + ', ' + user_street + (user_house ? ', ' + user_house : '')
          }

          this.$store.commit('loading', false)
        }).catch((e) => {
          // console.error(e)
          this.error = 'server_error'
          this.$scrollTo('.error__container', 300, { offset: -400 })
          this.$store.commit('loading', false)
        })
      }).catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$store.commit('loading', false)
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    },
    setBranch () {
      const branch = this.branches.find(b => this.branch == b.value)
      if (branch) {
        this.branchInfo = {
          phone: branch.data.phone,
          address: branch.data.address,
          description: branch.data.description,
        }
        this.setMarker(branch.data)
      }
    },
    setTimes () {
      if (this.branches.length == 0 || !this.branch || !this.date_formatted) {
        this.times = []
        return
      }
      const date = this.parseDate(this.date_formatted)
      const branch = this.branches.find(b => this.branch == b.value)
      this.times = branch.data.days[date] || []
    },
    setMarker (branch) {
      if (branch.latlon) {
        const latlon = branch.latlon.split(',')
        this.marker = true
        this.zoom = 14
        this.infoOptions.content = branch.name
        this.center = {lat: parseFloat(latlon[0] || 0), lng: parseFloat(latlon[1] || 0)}
      }
    }
  }
}
</script>

